/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

/* COMPONENTS */
import Layout from '../components/Layout/index'

/* CSS e SCSS */
import './styles/comer-melhor-cada-dia.scss'

/* Imagens SVG */

export default function ComerMelhorCadaDia(props) {
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/comer-melhor-cada-dia/', name: 'Comer Melhor Cada Dia' }
    ]
  }

  const Banner = () => (
    <>
      <section className="container-fluid p-0 d-lg-block d-none">
        <div className="backgroud-banner-comer w-100 d-flex align-items-center"></div>
      </section>
      <section className="container-fluid p-0 d-lg-none d-block">
        <div className="backgroud-banner-comer-mobile w-100"></div>
      </section>
    </>
  )

  const [selectedOption, setSelectedOption] = useState('2023')
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Comer Melhor a Cada Dia - Dia Supermercados</title>
        <meta name="description" content="Para ter uma dieta variada e equilibrada, além de otimizar seu orçamento, planeje suas refeições levando em consideração nosso decálogo de recomendações para comer melhor a cada dia." />
        <meta name="keywords" content="receitas, receitas" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section>
        <Banner />
        <div className='container'>
          <div className='my-5'>
            <p>Na Dia acreditamos que uma alimentação equilibrada e de qualidade deve estar ao alcance de todas as famílias. A nossa ambição é que os nossos clientes possam comer
              melhor todos os dias, onde quer que vivam e qualquer que seja o orçamento que tenham. As ações que fazem parte do nosso 'Comer melhor todos os dias' alimentam o nosso
              propósito de estarmos cada dia mais próximos e visam apoiar aquelas pessoas que optam por mudar gradualmente os seus hábitos alimentares para uma alimentação mais
              equilibrada.
            </p>
            <GatsbyImage
              image={props.data.bannerDesktop.childImageSharp.gatsbyImageData}
              className='my-3 d-lg-flex d-none'
              alt="Ver Catálogo"
            />
            <GatsbyImage
              image={props.data.bannerMobile.childImageSharp.gatsbyImageData}
              className='my-3 d-flex d-lg-none'
              alt="Ver Catálogo"
            />
          </div>
          <div className='my-5'>
            <h3>Comer Melhor Cada Dia</h3>
            <p>Para ter uma dieta variada e equilibrada, além de otimizar seu orçamento, planeje suas refeições levando em consideração nossas receitas e recomendações para comer melhor a cada dia.</p>
            <a href='https://tododiareceitas.com.br/' target="_blank" rel="noopener noreferrer">
              <GatsbyImage
                image={props.data.bannerDesktop2.childImageSharp.gatsbyImageData}
                className='my-3 d-lg-flex d-none'
                alt="Ver Catálogo"
              />
              <GatsbyImage
                image={props.data.bannerMobile2.childImageSharp.gatsbyImageData}
                className='my-3 d-flex d-lg-none'
                alt="Ver Catálogo"
              />
            </a>
          </div>
          <div className='mb-5'>
            <h3>Alimentos recomendados</h3>
            <div className='row d-flex mb-4'>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.banana.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Banana"
                />
                <p><b>Banana.</b> Originária do sudeste asiático, embora muitas vezes erroneamente tenha sido atribuída à América Central.</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.graoDeBico.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Grão de bico"
                />
                <p><b>Grão-de-bico.</b> São uma excelente fonte de fibra e de vitaminas e minerais.</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.avela.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Avelã"
                />
                <p><b>Avelãs.</b> Frutos secos com alto teor de ácidos graxos monoinsaturados, vitamina E, fitoesteróis e polifenóis.</p>
              </div>
            </div>
            <hr />
            <div className='row d-flex my-4'>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.melao.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Melão"
                />
                <p><b>Melão.</b> O melão é uma das frutas com maior teor de água (92%) e menor teor de açúcar (6%).</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.pimentaoVerde.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Pimentão Verde"
                />
                <p><b>Pimentão verde.</b> O pimentão verde é um vegetal com baixo teor calórico, proteico e de gorduras.</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.tomate.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Tomate"
                />
                <p><b>Tomate.</b> O tomate é composto principalmente por água, e seu macronutriente predominante são os carboidratos.</p>
              </div>
            </div>
            <hr />
            <div className='row d-flex my-4'>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.lentilha.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Lentilha"
                />
                <p><b>Lentilhas.</b> São baixas em gorduras e colesterol, além de serem uma fonte nutritiva e saudável de proteínas, fibras e outros nutrientes essenciais. Elas ajudam a cuidar da sua saúde e também do seu bolso.</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.laranja.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Laranja"
                />
                <p><b>Laranjas.</b> Sabia que as laranjas contêm uma grande quantidade de vitamina C, fortalecendo assim o sistema imunológico e prevenindo resfriados e outras infecções?</p>
              </div>
              <div className='col-lg-4'>
                <GatsbyImage
                  image={props.data.muesli.childImageSharp.gatsbyImageData}
                  className='my-3 image-border'
                  alt="Muesli"
                />
                <p><b>Muesli.</b> Está na moda e sabemos o porquê. O muesli é rico em fibras e nutrientes, tornando-se uma opção saudável para o seu café da manhã. Ainda não experimentou?</p>
              </div>
            </div>
            <div className='image-border'>
              <div className='row d-flex align-items-center p-3'>
                <div className='col-lg-4'>
                  <GatsbyImage
                    image={props.data.aproveitamento.childImageSharp.gatsbyImageData}
                    className='my-3 rounded'
                    alt="Pães"
                  />
                </div>
                <div className='col-lg-8'>
                  <p className='font-weight-bold mb-2'>Dicas de aproveitamento</p>
                  <p>Guarde o pão do dia em um saco de pano de algodão para mantê-lo fresco. Aproveite o pão duro para fazer migalhas de pão ou torradas.</p>
                </div>
              </div>
            </div>
            <div className='image-border my-3'>
              <div className='row d-flex align-items-center p-3'>
                <div className='col-lg-4'>
                  <GatsbyImage
                    image={props.data.conservacao.childImageSharp.gatsbyImageData}
                    className='my-3 rounded'
                    alt="Batatas"
                  />
                </div>
                <div className='col-lg-8'>
                  <p className='font-weight-bold mb-2'>Dicas de conservação</p>
                  <p>Para evitar que as batatas desenvolvam brotos, você pode colocar uma maçã na rede ou local onde as guarda. Além disso, não deve armazenar as batatas e as cebolas juntas, pois isso acelera o processo de decomposição.</p>
                </div>
              </div>
            </div>
            <div className='image-border'>
              <div className='row d-flex align-items-center p-3'>
                <div className='col-lg-4'>
                  <GatsbyImage
                    image={props.data.saudavel.childImageSharp.gatsbyImageData}
                    className='my-3 rounded'
                    alt="Abacate, nozes e azeite"
                  />
                </div>
                <div className='col-lg-8'>
                  <p className='font-weight-bold mb-2'>Dica saudável</p>
                  <p>Substitua as gorduras saturadas e trans (presentes em alimentos fritos, bolos e carnes processadas) por gorduras saudáveis (como azeite de oliva, abacate e nozes). As gorduras saudáveis podem ajudar a reduzir o risco de doenças cardíacas e melhorar os níveis de colesterol.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`{
  banana: file(relativePath: {eq: "comer-melhor-a-cada-dia/banana.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  graoDeBico: file(relativePath: {eq: "comer-melhor-a-cada-dia/grao-de-bico.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  avela: file(relativePath: {eq: "comer-melhor-a-cada-dia/avela.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  melao: file(relativePath: {eq: "comer-melhor-a-cada-dia/melao.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  pimentaoVerde: file(relativePath: {eq: "comer-melhor-a-cada-dia/pimentao-verde.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  tomate: file(relativePath: {eq: "comer-melhor-a-cada-dia/tomate.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  morango: file(relativePath: {eq: "comer-melhor-a-cada-dia/morango.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  castanhaDeCaju: file(relativePath: {eq: "comer-melhor-a-cada-dia/castanha-de-caju.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  ervilha: file(relativePath: {eq: "comer-melhor-a-cada-dia/ervilha.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  lentilha: file(relativePath: {eq: "comer-melhor-a-cada-dia/lentilha.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  laranja: file(relativePath: {eq: "comer-melhor-a-cada-dia/laranja.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  muesli: file(relativePath: {eq: "comer-melhor-a-cada-dia/muesli.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  aproveitamento: file(relativePath: {eq: "comer-melhor-a-cada-dia/aproveitamento.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  conservacao: file(relativePath: {eq: "comer-melhor-a-cada-dia/conservacao.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  saudavel: file(relativePath: {eq: "comer-melhor-a-cada-dia/saudavel.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDesktop: file(relativePath: {eq: "comer-melhor-a-cada-dia/banner-1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobile: file(relativePath: {eq: "comer-melhor-a-cada-dia/banner-1-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobile1: file(relativePath: {eq: "comer-melhor-a-cada-dia/1-banner-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDesktop1: file(relativePath: {eq: "comer-melhor-a-cada-dia/1-banner-desktop.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobile2: file(relativePath: {eq: "comer-melhor-a-cada-dia/2-banner-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerDesktop2: file(relativePath: {eq: "comer-melhor-a-cada-dia/2-banner-desktop.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
